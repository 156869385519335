import { useState, useCallback } from 'react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

const useFetch = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback(async (url, options) => {
        setIsLoading(true);
        setError(null);

        try {
            const res = await fetch(API_URL + url, options);
            const json = await res.json();

            if (res.ok) {
                setData(json.data);
            } else {
                setError(json);
            }
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    return { data, error, isLoading, fetchData };
};

export default useFetch;
