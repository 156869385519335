import Events from "../components/Events/Events";
import { formatDateTime, formatDate, exportToExcel } from "../util/utils";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';
const eventType = 'contacts';

const columns = [
    { key: 'email', header: 'Email' },
    { key: 'name', header: 'Name' },
    { key: 'message', header: 'Message' },
    { key: 'createdAt', header: 'Date', render: (event) => formatDateTime(event.createdAt) },
];

function ContactPage() {
    const exportHandler = async () => {
        const fileName = `Contacts as of ${formatDate(new Date())}`;
        await exportToExcel(`${API_URL}/${eventType}/export`, fileName);
    }

    return (
        <Events
            eventType={eventType}
            tableColumns={columns}
            exportHandler={exportHandler}
            listOnly
        />
    )
}

export default ContactPage;
