import { Form, useActionData, useNavigation } from 'react-router-dom';

import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Box,
    Alert,
    AlertIcon,
    AlertDescription,
    Image,
} from '@chakra-ui/react';

function AuthForm() {
  const data = useActionData();
  const navigation = useNavigation();

  const isSubmitting = navigation.state === 'submitting';

  return (
    <Flex height='100vh' alignItems='center' justifyContent='center'>
        <Box p={8} width='400px' borderWidth={1} borderRadius={8} boxShadow='lg'>
            <Flex alignItems='center' justifyContent='center'>
                <Image src='/logo.png' alt='logo' boxSize='100px' objectFit='contain' />
            </Flex>

            {/* Display errors */}
            {data && data.error && data.error.message && (
            <Box mb={4}>
                <Alert status='error' borderRadius={4}>
                    <AlertIcon />
                    <AlertDescription>{data.error.message}</AlertDescription>
                </Alert>
            </Box>
            )}
            <Box my={4} textAlign='left'>
                <Form method='post'>
                    <FormControl>
                        <FormLabel>Username</FormLabel>
                        <Input id='username' name='username' type='text' placeholder='username' required />
                    </FormControl>
                    <FormControl mt={6}>
                        <FormLabel>Password</FormLabel>
                        <Input id='password' name='password' type='password' placeholder='*******' required />
                    </FormControl>
                    <Button width='full' mt={4} type='submit' disabled={isSubmitting}>
                        {isSubmitting ? 'Signing in...' : 'Sign In'}
                    </Button>
                </Form>
            </Box>
        </Box>
    </Flex>
  );
}

export default AuthForm;
