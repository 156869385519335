import { Image } from "@chakra-ui/react";
import Events, { action as eventsAction } from "../components/Events/Events";
import { formatDate } from "../util/utils";

const eventType = 'webinars';

const columns = [
    {
      key: 'image',
      header: 'Image',
      render: (event) => event.imageUrl && <Image src={event.imageUrl} alt={event.title} width='100px' />
    },
    { key: 'title', header: 'Title' },
    { key: 'description', header: 'Description' },
    {
      key: 'date',
      header: 'Date',
      render: (event) => `${formatDate(event.date)}`
    },
    {
      key: 'registrationDate',
      header: 'Registration Date',
      render: (event) => `${formatDate(event.startRegistrationDate)} to ${formatDate(event.endRegistrationDate)}`
    },
    { key: 'link', header: 'Link' },
    { key: 'address', header: 'Address' },
];

const fields = [
    { name: 'title', label: 'Webinar Title', type: 'text', required: true },
    { name: 'description', label: 'Webinar Description', type: 'text', required: true },
    { name: 'date', label: 'Webinar Date', type: 'date', required: true },
    { name: 'registrationDate', label: 'Registration Date', type: 'dateRange', required: true },
    { name: 'link', label: 'Webinar Link', type: 'text', required: true },
    { name: 'address', label: 'Webinar Address', type: 'text', required: true },
    { name: 'image', label: 'Webinar Image', type: 'image', required: false },
];

function WebinarPage() {
    const setEventFields = (event) => {
        if (!event) return null;

        return {
            ...event,
            image: event.imageUrl,
            date: new Date(event.date),
            registrationDate: [new Date(event.startRegistrationDate), new Date(event.endRegistrationDate)],
        };
    };

    return (
        <Events
            eventType={eventType}
            tableColumns={columns}
            formFields={fields}
            setEventFields={setEventFields}
        />
    )
}

export default WebinarPage;

export const action = async ({ request }) => {
    return await eventsAction({ request, eventType, fields });
}
