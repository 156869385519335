import { Text, Table, Thead, Tr, Th, VStack, Tbody, Td, Box, Button, Flex } from "@chakra-ui/react";
import { useLoaderData, json, defer, Await } from 'react-router-dom';
import { Suspense } from 'react';
import { exportToExcel } from "../util/utils";
import { getColumnsForFormat } from "../util/competition_format_mapping";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

function CompetitionDetailsPage() {
  const { competition } = useLoaderData();

  const exportHandler = async (competition) => {
    await exportToExcel(`${API_URL}/competitions/${competition.id}/export`, competition.title);
  };

  return (
    <VStack spacing={8} w='full'>
      <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
        <Await resolve={competition}>
          {(loadedCompetition) => (
            <>
            <Flex w='full' alignItems='baseline'>
              <Box>
                <Button colorScheme='orange' borderRadius='2rem' onClick={() => exportHandler(loadedCompetition)}>Export</Button>
              </Box>
              <Text fontSize='2xl' p='0' flexGrow={1} textAlign='center'>Registered - {loadedCompetition.title}</Text>
            </Flex>
            <Flex w='full' alignItems='baseline'>
              <Table variant='striped' colorScheme='gray' w='full'>
                  <Thead>
                      <Tr>
                          {getColumnsForFormat(competition.format).map(column => (
                              <Th key={column.key}>{column.header}</Th>
                          ))}
                      </Tr>
                  </Thead>

                  <Tbody>
                      {!!competition.participants.length && (competition.participants.map(participant => (
                        <Tr key={participant.id || participant.teamName}>
                            {getColumnsForFormat(competition.format).map(column => (
                                <Td key={column.key}>
                                    {column.render ? column.render(participant) : participant[column.key]}
                                </Td>
                            ))}
                        </Tr>
                      )))}
                  </Tbody>
              </Table>
            </Flex>
            </>
          )}
        </Await>
      </Suspense>
    </VStack>
  );
}

export default CompetitionDetailsPage;

export async function loader({ params }) {
  const id = params.id;

  const response = await fetch(`${API_URL}/competitions/${id}`);

  if (!response.ok) {
    throw json(
      { message: 'Could not fetch details for selected event.' },
      {
        status: 500,
      }
    );
  }

  const resData = await response.json();

  return defer({
    competition: resData.data.event,
  });
}
