import React, { useRef, useState } from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';

function FileInput({ field, file }) {
    const [filePreview, setFilePreview] = useState(file ? file.name : '');
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Set the file's name as the preview
        setFilePreview(file.name);
    };

    const triggerFileSelect = () => {
        fileInputRef.current.click();
    };

    return (
        <Box mt={4} key={field.name}>
            <Box
                height="60px" // Reduced height since we're showing filename, not image preview
                border="2px dashed black"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={triggerFileSelect}
            >
                {!filePreview && <Icon as={FiUpload} />}
                {filePreview && <Text isTruncated maxWidth="90%">{filePreview}</Text>} {/* isTruncated and maxWidth ensure long filenames are displayed neatly */}
            </Box>

            <input
                type="file"
                name={field.name}
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept={field.accept}
            />
        </Box>
    );
}

export default FileInput;
