import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useRouteError, useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const error = useRouteError();
    const navigate = useNavigate()

    let title = 'An error occured';
    let message = 'Something went wrong!';

    if (error.status === 500) {
        title = 'Internal Server Error';
        message = error.data.message;
    }

    if (error.status === 404) {
        title = 'Not Found';
        message = 'The page you requested could not be found.';
    }

    if (error.status === 401) {
        title = 'Unauthorized';
        message = 'You are not authorized to access this page.';
    }

    return (
        <Flex height='100vh' alignItems='center' justifyContent='center'>
            <Box p={8} width='400px' borderWidth={1} borderRadius={8} boxShadow='lg'>
                <Box textAlign='center'>
                    <Text size='lg'>{title}</Text>
                    <Text size='md'>{message}</Text>
                    <Button onClick={() => navigate(-1)}>Go Back</Button>
                </Box>
            </Box>
        </Flex>
    );
};

export default ErrorPage;
