import { formatDate } from "./utils";

// Common Fields
const commonFields = {
    representativeFields: [
      { key: 'repName', header: 'Name of Representative' },
      { key: 'institutionName', header: 'Institution Name' },
      { key: 'repEmail', header: 'Email Address' },
    ],
    debaterFields: (debaterNumber) => ([
      { key: `${debaterNumber}Name`, header: `Debater ${debaterNumber} Name` },
      { key: `${debaterNumber}Dob`, header: `Date of Birth`, render: participant => formatDate(participant[`${debaterNumber}Dob`]) },
      { key: `${debaterNumber}Grade`, header: `Grade/Year Level` },
      { key: `${debaterNumber}Email`, header: `Email Address` },
    ])
};

  // Column Mapping based on event type
const eventColumnsMapping = {
    publicForumDebate: [
      ...commonFields.representativeFields,
      ...commonFields.debaterFields(1),
      ...commonFields.debaterFields(2),
      { key: 'division', header: 'Division' } // Division specific to this event type
    ],
    wsdc: [
      ...commonFields.representativeFields,
      ...commonFields.debaterFields(1),
      ...commonFields.debaterFields(2),
      ...commonFields.debaterFields(3),
      ...commonFields.debaterFields(4), // Optional, so you may need to check if data exists
      ...commonFields.debaterFields(5), // Optional
      { key: 'division', header: 'Division' }
    ],
    juniorDebate: [
      ...commonFields.representativeFields,
      ...commonFields.debaterFields(1),
      ...commonFields.debaterFields(2),
      ...commonFields.debaterFields(3)
    ],
    storytelling: [
      ...commonFields.representativeFields,
      { key: 'speakerName', header: 'Speaker Name' },
      { key: 'speakerDob', header: 'Date of Birth', render: participant => formatDate(participant.speakerDob) },
      { key: 'speakerGrade', header: 'Grade/Year Level' },
      { key: 'speakerEmail', header: 'Email Address' },
      { key: 'division', header: 'Division' }
    ],
    originalOratory: [
      ...commonFields.representativeFields,
      { key: 'speakerName', header: 'Speaker Name' },
      { key: 'speakerDob', header: 'Date of Birth', render: participant => formatDate(participant.speakerDob) },
      { key: 'speakerGrade', header: 'Grade/Year Level' },
      { key: 'speakerEmail', header: 'Email Address' },
      { key: 'division', header: 'Division' }
    ],
    impromptu: [
      ...commonFields.representativeFields,
      { key: 'speakerName', header: 'Speaker Name' },
      { key: 'speakerDob', header: 'Date of Birth', render: participant => formatDate(participant.speakerDob) },
      { key: 'speakerGrade', header: 'Grade/Year Level' },
      { key: 'speakerEmail', header: 'Email Address' },
      { key: 'division', header: 'Division' }
    ]
};

const formats = {
    publicForumDebate: 'Public Forum Debate',
    wsdc: 'WSDC',
    juniorDebate: 'Junior Debate',
    storytelling: 'Storytelling',
    originalOratory: 'Original Oratory',
    impromptu: 'Impromptu Speaking',
};

export function getColumnsForFormat(format) {
  return eventColumnsMapping[format] || []; // Return the columns for the given event type or an empty array if not found
}

export function getLabelFormat(format) {
    return formats[format] || ''; // Return the label for the given event type or an empty string if not found
}
