import { json, redirect } from 'react-router-dom';
import AuthForm from '../components/AuthForm';

const API_URL = process.env.REACT_APP_API_URL;

function AuthenticationPage() {
  return <AuthForm />;
}

export default AuthenticationPage;

export const action = async ({ request }) => {
  const data = await request.formData();

  const authData = {
    username: data.get('username'),
    password: data.get('password'),
  };

  const response = await fetch(
    `${API_URL}/auth/login`,
    {
      method: 'POST',
      body: JSON.stringify(authData),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if ([422, 403, 401].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw json(
      { message: 'Could not authenticate you.' },
      { status: 500 }
    );
  }

  const responseJson = await response.json();
  const responseData = responseJson.data;
  const token = responseData.token;

  localStorage.setItem('token', token);
  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);
  localStorage.setItem('expiration', expiration.toISOString());

  return redirect('/competitions');
};
