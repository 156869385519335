import { Box, Image } from "@chakra-ui/react";
import Events, { action as eventsAction } from "../components/Events/Events";
import 'react-quill/dist/quill.snow.css';

const eventType = 'updates';

const columns = [
    {
      key: 'image',
      header: 'Image',
      render: (event) => event.imageUrl && <Image src={event.imageUrl} alt={event.title} width='100px' />
    },
    { key: 'title', header: 'Title' },
    {
        key: 'content',
        header: 'content',
        render: (event) => <Box dangerouslySetInnerHTML={{ __html: event.content }} />
    },
];

const fields = [
    { name: 'title', label: 'Updates Title', type: 'text', required: true },
    {
        name: 'type',
        label: 'Updates Type',
        type: 'select',
        required: true,
        options: [
            { value: 'articles', label: 'Articles' },
            { value: 'sampleCases', label: 'Sample Cases' },
            { value: 'marketing', label: 'Marketing' },
            { value: 'pastEvents', label: 'Past Events' },
            { value: 'upcomingEvents', label: 'Upcoming Events' },
            { value: 'skills', label: 'Skills' },
            { value: 'competitionResults', label: 'Competition Results' },
            { value: 'achievements', label: 'Achievements' },
            { value: 'studentProfiles', label: 'Student Profiles' },
        ]
    },
    { name: 'content', label: 'Updates Content', type: 'wysiwyg', required: true },
    { name: 'image', label: 'Updates Image', type: 'image', required: false },
];

function UpdatePage() {
    const setEventFields = (event) => {
        if (!event) return null;

        return {
            ...event,
            image: event.imageUrl,
        };
    };

    return (
        <Events
            eventType={eventType}
            tableColumns={columns}
            formFields={fields}
            setEventFields={setEventFields}
        />
    )
}

export default UpdatePage;

export const action = async ({ request }) => {
    return await eventsAction({ request, eventType, fields });
}
