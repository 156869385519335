import { Text, Table, Thead, Tr, Th, VStack, Tbody, Td, Box, Button, Flex } from "@chakra-ui/react";
import { useLoaderData, json, defer, Await } from 'react-router-dom';
import { Suspense } from 'react';
import { exportToExcel } from "../util/utils";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

function WorkshopDetailsPage() {
  const { workshop } = useLoaderData();

  const columns = [
      { key: 'name', header: 'Name' },
      { key: 'schoolName', header: 'Institution/School Name' },
      { key: 'grade', header: 'Grade' },
      { key: 'email', header: 'Email' },
  ];

  const exportHandler = async (workshop) => {
    await exportToExcel(`${API_URL}/workshops/${workshop.id}/export`, workshop.title);
  };


  return (
    <VStack spacing={8} w='full'>
      <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
        <Await resolve={workshop}>
          {(loadedWorkshop) => (
            <>
            <Flex w='full' alignItems='baseline'>
              <Box>
                <Button colorScheme='orange' borderRadius='2rem' onClick={() => exportHandler(loadedWorkshop)}>Export</Button>
              </Box>
              <Text fontSize='2xl' p='0' flexGrow={1} textAlign='center'>Registered - {loadedWorkshop.title}</Text>
            </Flex>
            <Flex w='full' alignItems='baseline'>
              <Table variant='striped' colorScheme='gray' w='full'>
                  <Thead>
                      <Tr>
                          {columns.map(column => (
                              <Th key={column.key}>{column.header}</Th>
                          ))}
                      </Tr>
                  </Thead>

                  <Tbody>
                      {!!workshop.participants.length && (workshop.participants.map(participant => (
                        <Tr key={participant.id || participant.name}>
                            {columns.map(column => (
                                <Td key={column.key}>
                                    {column.render ? column.render(participant) : participant[column.key]}
                                </Td>
                            ))}
                        </Tr>
                      )))}
                  </Tbody>
              </Table>
            </Flex>
            </>
          )}
        </Await>
      </Suspense>
    </VStack>
  );
}

export default WorkshopDetailsPage;

export async function loader({ params }) {
  const id = params.id;

  const response = await fetch(`${API_URL}/workshops/${id}`);

  if (!response.ok) {
    throw json(
      { message: 'Could not fetch details for selected event.' },
      {
        status: 500,
      }
    );
  }

  const resData = await response.json();

  return defer({
    workshop: resData.data.event,
  });
}
