import { format } from 'date-fns';
import { json } from "react-router-dom";

export function formatDate(date) {
    return format(new Date(date), 'dd MMM yyyy');
}

export function formatDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy hh:mm a');
}

// capitalize first letter and remove s from the end if there is
export function formatEventType(eventType) {
    return eventType.charAt(0).toUpperCase() + eventType.slice(1).replace(/s$/, '');
}

export async function exportToExcel(apiUrl, fileName) {
    const response = await fetch(apiUrl);

    if (!response.ok) {
        throw json(
            { message: 'Could not export subscribers.' },
            { status: 500 },
        );
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();

    // Release the reference to the object URL
    window.URL.revokeObjectURL(url);
}

