import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import Events from "../components/Events/Events";
import { formatDateTime, formatDate, exportToExcel } from "../util/utils";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';
const eventType = 'memberships';

function MembershipPage() {
    const exportHandler = async () => {
        const fileName = `Memberships as of ${formatDate(new Date())}`;
        await exportToExcel(`${API_URL}/${eventType}/export`, fileName);
    }

    const membershipTypes = [
        {
            type: 'School',
            columns: [
                { key: 'email', header: 'Email' },
                { key: 'schoolName', header: 'School Name' },
                { key: 'country', header: 'Country' },
                { key: 'contactPerson', header: 'Contact Person' },
                { key: 'createdAt', header: 'Date', render: (event) => formatDateTime(event.createdAt) },
            ],
        },
        {
            type: 'Non-School',
            columns: [
                { key: 'email', header: 'Email' },
                { key: 'organizationName', header: 'Organization Name' },
                { key: 'country', header: 'Country' },
                { key: 'contactPerson', header: 'Contact Person' },
                { key: 'createdAt', header: 'Date', render: (event) => formatDateTime(event.createdAt) },
            ],
        },
        {
            type: 'Individual',
            columns: [
                { key: 'email', header: 'Email' },
                { key: 'fullName', header: 'Full Name' },
                { key: 'country', header: 'Country' },
                { key: 'createdAt', header: 'Date', render: (event) => formatDateTime(event.createdAt) },
            ],
        }
    ];

    return (
        <Box w='full'>
            <Button colorScheme='orange' borderRadius='2rem' onClick={exportHandler} mb={5}>Export</Button>
            <Tabs>
                <TabList>
                    {membershipTypes.map((membershipType) => (
                        <Tab key={membershipType.type}>{membershipType.type}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                {membershipTypes.map((membershipTypes) => (
                    <TabPanel key={membershipTypes.type}>
                        <Events
                            key={membershipTypes.type}
                            eventType={`${eventType}?type=${membershipTypes.type}`}
                            tableColumns={membershipTypes.columns}
                            listOnly
                        />
                    </TabPanel>
                ))}
                </TabPanels>
            </Tabs>
        </Box>
    )
}

export default MembershipPage;
