import { Image } from "@chakra-ui/react";
import Events, { action as eventsAction } from "../components/Events/Events";
import { getLabelFormat } from "../util/competition_format_mapping";
import { formatDate } from "../util/utils";

const eventType = 'workshops';

const columns = [
  {
    key: 'image',
    header: 'Image',
    render: (event) => event.imageUrl && <Image src={event.imageUrl} alt={event.title} width='100px' />
  },
  { key: 'title', header: 'Title' },
  { key: 'description', header: 'Description' },
  {
    key: 'date',
    header: 'Date',
    render: (event) => `${formatDate(event.startDate)} to ${formatDate(event.endDate)}`
  },
  {
    key: 'registrationDate',
    header: 'Registration Date',
    render: (event) => `${formatDate(event.startRegistrationDate)} to ${formatDate(event.endRegistrationDate)}`
  },
  {
    key: 'format',
    header: 'Format',
    render: (event) => getLabelFormat(event.format),
  },
  { key: 'hosts', header: 'Host' },
  { key: 'address', header: 'Address' },
  { key: 'price', header: 'Price' },
];

const fields = [
  { name: 'title', label: 'Workshop Title', type: 'text', required: true },
  { name: 'description', label: 'Workshop Description', type: 'text', required: true },
  { name: 'date', label: 'Workshop Date', type: 'dateRange', required: true },
  { name: 'registrationDate', label: 'Registration Date', type: 'dateRange', required: true },
  {
    name: 'format',
    label: 'Workshop Format',
    type: 'select',
    required: true,
    options: [
      { value: 'publicForumDebate', label: 'Public Forum Debate' },
      { value: 'wsdc', label: 'WSDC' },
      { value: 'juniorDebate', label: 'Junior Debate' },
      { value: 'storytelling', label: 'Storytelling' },
      { value: 'originalOratory', label: 'Original Oratory' },
      { value: 'impromptu', label: 'Impromptu Speaking' },
    ]
  },
  { name: 'hosts', label: 'Workshop Host', type: 'text', required: true },
  { name: 'address', label: 'Workshop Address', type: 'text', required: true },
  { name: 'price', label: 'Workshop Price', type: 'text', required: true },
  { name: 'image', label: 'Workshop Image', type: 'image', required: false },
];

function WorkshopPage() {
  const setEventFields = (event) => {
        if (!event) return null;

        return {
            ...event,
            image: event.imageUrl,
            date: [new Date(event.startDate), new Date(event.endDate)],
            registrationDate: [new Date(event.startRegistrationDate), new Date(event.endRegistrationDate)],
        };
    };

    return (
        <Events
            eventType={eventType}
            tableColumns={columns}
            formFields={fields}
            setEventFields={setEventFields}
        />
    )
}

export default WorkshopPage;

export const action = async ({ request }) => {
  return await eventsAction({ request, eventType, fields });
}
