import { Text, Table, Thead, Tr, Th, VStack, Tbody, Td, Box, Button, Flex } from "@chakra-ui/react";
import { useLoaderData, json, defer, Await } from 'react-router-dom';
import { Suspense } from 'react';
import { exportToExcel, formatDate } from "../util/utils";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

function WebinarDetailsPage() {
  const { webinar } = useLoaderData();

  const columns = [
      { key: 'name', header: 'Name' },
      { key: 'email', header: 'Email' },
      { key: 'occupation', header: 'Occupation' },
      {
        key: 'dob',
        header: 'Date of Birth',
        render: (participant) => `${formatDate(participant.dob)}`
      },
  ];

  const exportHandler = async (webinar) => {
    await exportToExcel(`${API_URL}/webinars/${webinar.id}/export`, webinar.title);
  };


  return (
    <VStack spacing={8} w='full'>
      <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
        <Await resolve={webinar}>
          {(loadedWebinar) => (
            <>
            <Flex w='full' alignItems='baseline'>
              <Box>
                <Button colorScheme='orange' borderRadius='2rem' onClick={() => exportHandler(loadedWebinar)}>Export</Button>
              </Box>
              <Text fontSize='2xl' p='0' flexGrow={1} textAlign='center'>Registered - {loadedWebinar.title}</Text>
            </Flex>
            <Flex w='full' alignItems='baseline'>
              <Table variant='striped' colorScheme='gray' w='full'>
                  <Thead>
                      <Tr>
                          {columns.map(column => (
                              <Th key={column.key}>{column.header}</Th>
                          ))}
                      </Tr>
                  </Thead>

                  <Tbody>
                      {!!webinar.participants.length && (webinar.participants.map(participant => (
                        <Tr key={participant.id || participant.name}>
                            {columns.map(column => (
                                <Td key={column.key}>
                                    {column.render ? column.render(participant) : participant[column.key]}
                                </Td>
                            ))}
                        </Tr>
                      )))}
                  </Tbody>
              </Table>
            </Flex>
            </>
          )}
        </Await>
      </Suspense>
    </VStack>
  );
}

export default WebinarDetailsPage;

export async function loader({ params }) {
  const id = params.id;

  const response = await fetch(`${API_URL}/webinars/${id}`);

  if (!response.ok) {
    throw json(
      { message: 'Could not fetch details for selected event.' },
      {
        status: 500,
      }
    );
  }

  const resData = await response.json();

  return defer({
    webinar: resData.data.event,
  });
}
