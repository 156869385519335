import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select } from '@chakra-ui/react';
import { Form, useSubmit } from 'react-router-dom';
import { RangeDatepicker, SingleDatepicker } from "chakra-dayzed-datepicker";
import UploadComponent from '../UploadButton';
import { useState } from 'react';
import { formatEventType } from '../../util/utils';
import ReactQuill from 'react-quill';
import FileInput from '../FileInput';

function AddEventModal(props) {
    const [event, setEvent] = useState(props.event || {});
    const submit = useSubmit();
    const method = event.id ? 'PUT' : 'POST';
    const action = event.id ? 'Edit' : 'Add';

    const onChangeHandler = (eventInput) => {
        const { name, value } = eventInput.target;
        setEvent({ ...event, [name]: value });
    };

    const renderField = (field) => {
        switch (field.type) {
            case 'text':
            case 'number':
                return (
                    <FormControl mt={4} key={field.name}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input type={field.type} name={field.name} value={event[field.name] || ''} onChange={onChangeHandler} min={1} required />
                    </FormControl>
                );
            case 'date':
                return (
                    <FormControl mt={4} key={field.name}>
                        <FormLabel>{field.label}</FormLabel>
                        <SingleDatepicker
                            name='date'
                            date={event[field.name] || new Date()}
                            onDateChange={(val) => onChangeHandler({
                                target: {
                                    name: field.name,
                                    value: val,
                                }
                            })}
                        />
                    </FormControl>
                );
            case 'dateRange':
                return (
                    <FormControl mt={4} key={field.name}>
                        <FormLabel>{field.label}</FormLabel>
                        <RangeDatepicker
                            name={field.name}
                            selectedDates={event[field.name] || [new Date(), new Date()]}
                            onDateChange={(val) => onChangeHandler({
                                target: {
                                    name: field.name,
                                    value: val,
                                }
                            })}
                        />
                    </FormControl>
                );
            case 'image':
                return (
                    <FormControl mt={4} key={field.name}>
                        <FormLabel>{field.label}</FormLabel>
                        <UploadComponent name={field.name} imageUrl={event[field.name]} />
                    </FormControl>
                );
            case 'wysiwyg':
                return (
                    <FormControl mt={4} key={field.name}>
                        <FormLabel>{field.label}</FormLabel>
                        <ReactQuill theme="snow" name={field.name} value={event[field.name] || ''} onChange={(val) => onChangeHandler({
                            target: {
                                name: field.name,
                                value: val,
                            }
                        })} />
                    </FormControl>
                );
            case 'select':
                return (
                    <FormControl mt={4} key={field.name}>
                        <FormLabel>{field.label}</FormLabel>
                        <Select name={field.name} value={event[field.name] || ''} onChange={onChangeHandler} required>
                            {field.options.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'file':
                return (
                    <FormControl mt={4} key={field.name}>
                        <FormLabel>{field.label}</FormLabel>
                        <FileInput field={field} file={event[field.name]} onChangeHandler={onChangeHandler} />
                    </FormControl>
                );
            default:
                break;
        }
    };

    const submitHandler = (eventInput) => {
        eventInput.preventDefault();
        const formData = new FormData(eventInput.target);

        props.fields.forEach(field => {
            if (!formData.get(field.name)) {
                formData.append(field.name, event[field.name]);
            }
        });

        if (event.id) {
            formData.append('id', event.id)
        };

        submit(formData, { action: `/api/${props.eventType}`, method, encType: 'multipart/form-data' });
    };

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <Form onSubmit={submitHandler}>
                    <ModalHeader>{action} {formatEventType(props.eventType)}</ModalHeader>
                    <ModalCloseButton onClick={props.onClose} />
                    <ModalBody>
                        <Input
                            display='none'
                            type='text'
                            name='id'
                            value={event.id}
                            onChange={onChangeHandler}
                        />

                        {props.fields.map(field => renderField(field))}
                    </ModalBody>

                    <ModalFooter>
                        <Button type='submit'>{action} {formatEventType(props.eventType)}</Button>
                    </ModalFooter>
                </Form>
            </ModalContent>
        </Modal>
    )
}

export default AddEventModal;
