import { Button, Flex, Table, Thead, Tr, Th, useDisclosure, VStack, Tbody, Td, IconButton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import AddEventModal from "./AddEventModal";
import useFetch from "../../hooks/use-fetch";
import { BsPencilFill, BsTrash2Fill, BsPeopleFill } from 'react-icons/bs';
import DeleteEventModal from "./DeleteEventModal";
import { formatEventType } from "../../util/utils";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

function Events({
    eventType = 'competitions',
    tableColumns = [],
    setEventFields = (event) => event,
    formFields = [],
    listOnly = false,
    ...props
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [event, setEvent] = useState({});
  const { isOpen: isAddEditOpen, onOpen: onOpenAddEdit, onClose: onCloseAddEdit } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const { data, fetchData } = useFetch();
  const { events = [] } = data || {};

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get('success') === 'true') {
      onCloseAddEdit();
      onCloseDelete();
      navigate(location.pathname, { replace: true });
    }

    fetchData(`/${eventType}`);
  }, [eventType, location, navigate, onCloseAddEdit, onCloseDelete, fetchData]);

  const handleOnClose = () => {
    onCloseAddEdit();
    navigate(`${location.pathname}`);
  };

  const handleOnOpen = (event, action = 'edit') => {
    setEvent(setEventFields(event || null));

    if (action === 'edit' || action === 'add') {
      return onOpenAddEdit();
    }

    onOpenDelete();
  };

  return (
    <VStack spacing={8} w='full'>
      <Flex direction={['column', 'row']}  w='full'>
        {!listOnly && <Button colorScheme='orange' borderRadius='2rem' onClick={() => handleOnOpen()}>Add {formatEventType(eventType)}</Button>}
        {props.exportHandler && <Button colorScheme='orange' borderRadius='2rem' onClick={() => props.exportHandler()}>Export</Button>}
      </Flex>

      <Table variant='striped' colorScheme='gray' w='full'>
        <Thead>
            <Tr>
                {tableColumns.map(column => (
                    <Th key={column.key}>{column.header}</Th>
                ))}
                {!listOnly && ( <Th>Actions</Th> ) }
            </Tr>
        </Thead>

        <Tbody>
          {!!events.length && (events.map(event => (
            <Tr key={event.id}>
                {tableColumns.map(column => (
                    <Td key={column.key}>
                        {column.render ? column.render(event) : event[column.key]}
                    </Td>
                ))}
                {!listOnly && (
                <Td>
                    <Flex direction='row'>
                      <IconButton icon={<BsPencilFill />} colorScheme='orange' mr={2} onClick={() => handleOnOpen(event)} />
                      <IconButton icon={<BsTrash2Fill />} colorScheme='red' onClick={() => handleOnOpen(event, 'delete')} />
                      {['competitions', 'webinars', 'camps', 'workshops'].includes(eventType) && (
                        <Link to={`/${eventType}/${event.id}`}>
                          <IconButton icon={<BsPeopleFill />} colorScheme='orange' ml={2} />
                        </Link>
                      )}
                    </Flex>
                </Td>
                )}
            </Tr>
          )))}
        </Tbody>
      </Table>

      {!listOnly && isAddEditOpen && <AddEventModal isOpen={isAddEditOpen} onClose={handleOnClose} event={event} fields={formFields} eventType={eventType} /> }
      {!listOnly && isDeleteOpen && <DeleteEventModal isOpen={isDeleteOpen} onClose={onCloseDelete} event={event} fields={formFields} eventType={eventType} />}
    </VStack>
  )
}

export default Events;

export const action = async ({ request, eventType, fields }) => {
    const method = request.method;
    const data = await request.formData();
    const fieldNames = fields?.map(field => field.name) || [];
    const event = fieldNames.reduce((acc, fieldName) => {
        acc[fieldName] = data.get(fieldName);
        return acc;
    }, {});

    const imageField = fieldNames.includes('photo') ? 'photo' : 'image';

    let payload = new FormData();
    payload.append('event', JSON.stringify(event));
    payload.append('photo', data.get(imageField));
    if (data.get('file')) {
      payload.append('file', data.get('file'));
    }

    const options = {
        method,
        body: payload,
    };

    if (method === 'DELETE') {
        options.body = JSON.stringify(event);
        options.headers = {
            'Content-Type': 'application/json',
        };
    }

    await fetch(
        `${API_URL}/${eventType}${data.get('id') ? `/${data.get('id')}` : ''}`,
        options,
    );

    return redirect(`/${eventType}?success=true`);
};

