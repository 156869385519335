import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { useSubmit, } from 'react-router-dom';
import { formatEventType } from '../../util/utils';

function DeleteEventModal(props) {
    const submit = useSubmit();

    const onDeleteItemHandler = (event) => {
        event.preventDefault();

        submit(props.event, { action: `/api/${props.eventType}`, method: 'delete' });
    };

    return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Delete {formatEventType(props.eventType)}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text>Are you sure you want to delete {props.event?.title}?</Text>
            </ModalBody>
            <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onDeleteItemHandler}>
                Delete
            </Button>
            <Button variant="ghost" onClick={props.onClose}>Cancel</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}

export default DeleteEventModal;
