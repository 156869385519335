import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet, useLoaderData, useNavigation, useSubmit } from 'react-router-dom';
import Navbar from '../components/Navbar';

import { getTokenDuration } from '../util/auth';

function RootLayout() {
  const navigation = useNavigation();
  const token = useLoaderData();
  const submit = useSubmit();

  useEffect(() => {
    if (!token) {
      return;
    }

    if (token === 'EXPIRED') {
      submit(null, { action: '/logout', method: 'post' });
      return;
    }

    const tokenDuration = getTokenDuration();

    setTimeout(() => {
      submit(null, { action: '/logout', method: 'post' });
    }, tokenDuration);
  }, [token, submit]);

  return (
    <>
      <Box px='2rem'>
        {token && token !== 'EXPIRED' && <Navbar />}
        {navigation.state === 'loading' && <p>Loading...</p>}
        <Outlet />
      </Box>
    </>
  );
}

export default RootLayout;
