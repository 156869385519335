import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';


import ErrorPage from './pages/Error';
import RootLayout from './pages/Root';
import AuthenticationPage, { action as authAction } from './pages/Authentication';
import { action as logoutAction } from './pages/Logout';
import { checkAuthLoader, loader as tokenLoader } from './util/auth';

import WebinarPage, { action as addWebinarAction } from './pages/Webinar';
import UpdatePage, { action as addUpdateAction } from './pages/Updates';
import CompetitionPage, { action as addCompetitionAction } from './pages/Competition';
import SubscriberPage from './pages/Subscribers';
import CompetitionDetailsPage, { loader as competitionDetailsLoader } from './pages/CompetitionDetails';
import ContactPage from './pages/Contacts';
import WebinarDetailsPage,  { loader as webinarDetailsLoader } from './pages/WebinarDetails';
import ResourcesPage, { action as addResourceAction } from './pages/Resources';
import MembershipPage from './pages/Memberships';
import CampPage, { action as addCampAction } from './pages/Camps';
import CampDetailsPage,  { loader as campDetailsLoader } from './pages/CampDetails';
import WorkshopPage, { action as addWorkshopAction } from './pages/Workshops';
import WorkshopDetailsPage,  { loader as workshopDetailsLoader } from './pages/WorkshopDetails';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: 'root',
    loader: tokenLoader,
    children: [
      { index: true, element: <CompetitionPage />, loader: checkAuthLoader },
      {
        path: 'competitions',
        element: <Outlet />,
        loader: checkAuthLoader,
        children: [
          { index: true, element: <CompetitionPage />, loader: checkAuthLoader },
          {
            path: ':id',
            element: <CompetitionDetailsPage />,
            loader: competitionDetailsLoader,
          },
        ],
      },
      {
        path: 'webinars',
        element: <Outlet />,
        loader: checkAuthLoader,
        children: [
          { index: true, element: <WebinarPage />, loader: checkAuthLoader },
          {
            path: ':id',
            element: <WebinarDetailsPage />,
            loader: webinarDetailsLoader,
          },
        ],
      },
      { path: 'resources', element: <ResourcesPage />, loader: checkAuthLoader },
      { path: 'updates', element: <UpdatePage />, loader: checkAuthLoader },
      { path: 'subscribers', element: <SubscriberPage />, loader: checkAuthLoader },
      { path: 'contacts', element: <ContactPage />, loader: checkAuthLoader },
      { path: 'memberships', element: <MembershipPage />, loader: checkAuthLoader },
      {
        path: 'camps',
        element: <Outlet />,
        loader: checkAuthLoader,
        children: [
          { index: true, element: <CampPage />, loader: checkAuthLoader },
          {
            path: ':id',
            element: <CampDetailsPage />,
            loader: campDetailsLoader,
          },
        ],
      },
      {
        path: 'workshops',
        element: <Outlet />,
        loader: checkAuthLoader,
        children: [
          { index: true, element: <WorkshopPage />, loader: checkAuthLoader },
          {
            path: ':id',
            element: <WorkshopDetailsPage />,
            loader: workshopDetailsLoader,
          },
        ],
      },
      {
        path: 'login',
        element: <AuthenticationPage />,
        action: authAction,
      },
      {
        path: 'logout',
        action: logoutAction,
      },
      { path: 'api/competitions', action: addCompetitionAction },
      { path: 'api/webinars', action: addWebinarAction },
      { path: 'api/updates', action: addUpdateAction },
      { path: 'api/resources', action: addResourceAction },
      { path: 'api/camps', action: addCampAction },
      { path: 'api/workshops', action: addWorkshopAction },
    ],
  }
]);

function App() {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  )
}

export default App;
