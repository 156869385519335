import Events, { action as eventsAction } from "../components/Events/Events";
import { formatDate } from "../util/utils";

const eventType = 'resources';

const columns = [
    { key: 'name', header: 'Name' },
    {
        key: 'file',
        header: 'File',
        render: (event) => (event.file && event.file.name) || '',
    },
    {
        key: 'category',
        header: 'Category',
        render: (event) => event.category
    },
    {
      key: 'date',
      header: 'Date',
      render: (event) => `${formatDate(event.createdAt)}`
    },
];

const fields = [
    { name: 'name', label: 'Name', type: 'text', required: true },
    {
        name: 'category',
        label: 'Category',
        type: 'select',
        required: true,
        options: [
            { value: 'debate', label: 'Debate' },
            { value: 'public speaking', label: 'Public Speaking' },
        ]
    },
    { name: 'file', label: 'File', type: 'file', required: true, accept: '.pdf, .doc, .docx' },
];

function ResourcesPage() {
    const setEventFields = (event) => {
        if (!event) return null;

        return {
            ...event,
        };
    };

    return (
        <Events
            eventType={eventType}
            tableColumns={columns}
            formFields={fields}
            setEventFields={setEventFields}
        />
    )
}

export default ResourcesPage;

export const action = async ({ request }) => {
    return await eventsAction({ request, eventType, fields });
}
