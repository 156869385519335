import { Box, Button, Flex, HStack, Image, Spacer } from '@chakra-ui/react';
import { Form, NavLink } from 'react-router-dom';  // import from react-router-dom

function Navbar() {
  const links = [
    { to: '/competitions', label: 'Competitions' },
    { to: '/webinars', label: 'Webinars' },
    { to: '/workshops', label: 'Workshops' },
    { to: '/camps', label: 'Camps' },
    { to: '/resources', label: 'Resources' },
    { to: '/subscribers', label: 'Subscribers' },
    { to: '/updates', label: 'Updates' },
    { to: '/contacts', label: 'Contacts' },
    { to: '/memberships', label: 'Memberships' },
  ];

  return (
    <Box w='100%'>
      <HStack spacing='4rem'>
        <Image src='/logo.png' alt='logo' boxSize='100px' objectFit='contain' />
        <HStack spacing={16}>
        {links.map(link => (
            <NavLink
              key={link.to}
              to={link.to}
              style={({ isActive }) => {
                return {
                  textDecoration: 'none',
                  color: isActive ? 'orange' : 'gray',
                };
              }}
            >
              {link.label}
            </NavLink>
          ))}
        </HStack>
        <Flex flexGrow='1'>
            <Spacer />
            <Form action='/logout' method="post">
                <Button colorScheme='orange' borderRadius='2rem' type='submit'>Logout</Button>
            </Form>
        </Flex>
      </HStack>
    </Box>
  );
}

export default Navbar;
