import React, { useRef, useState } from 'react';
import { Box, Icon, Image } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';

function UploadComponent(props) {
    const [imagePreview, setImagePreview] = useState(props.imageUrl);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
    };

    const triggerFileSelect = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <Box
                height="200px"
                border="2px dashed black"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={triggerFileSelect}
            >
                {!imagePreview && <Icon as={FiUpload} />}
                {imagePreview && (
                    <Image
                        src={imagePreview}
                        alt="Selected Preview"
                        objectFit="contain"
                        width="100%"
                        height="100%"
                        top="0"
                        left="0"
                    />
                )}
            </Box>
            <input
                type="file"
                name={props.name}
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*"
            />
        </div>
    );
}

export default UploadComponent;
