import Events from "../components/Events/Events";
import 'react-quill/dist/quill.snow.css';
import { exportToExcel, formatDate } from "../util/utils";

const eventType = 'subscribers';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

const columns = [
    { key: 'email', header: 'Email' },
];

function SubscriberPage() {
    const setEventFields = (event) => {
        if (!event) return null;

        return {
            ...event,
            image: event.imageUrl,
        };
    };

    const exportHandler = async () => {
        const fileName = `Subscribers as of ${formatDate(new Date())}`;
        await exportToExcel(`${API_URL}/${eventType}/export`, fileName);
    };

    return (
        <Events
            eventType={eventType}
            tableColumns={columns}
            setEventFields={setEventFields}
            exportHandler={exportHandler}
            listOnly
        />
    )
}

export default SubscriberPage;
